# Main file

(($, Mobile, Util, Gallery, Popup) ->

  # Initialization
  $ ->
    Mobile.init()
    Gallery.init()
    Popup.init()

    Util.scrollUp()

    Util.offsetAnchorTop 140
    Util.inFieldLabels '.field-wrap, .gfield'
    Util.scrollToLinks $('.scroll-to')
    Util.toggleLinks()
    Util.markLoadedImages()
    syncNewsletterFields()

    # Hide the collections page before loading
    preloadCollection()

    # Stretch to fill the screen
    topMargin = parseInt $('.main-content').css('margin-top').replace('px', '')
    footerHeight = $('.main-footer').height()
    Util.fullScreenSection '.main-content', topMargin + footerHeight
    markScrolledDown()

    # Mobile menu's dynamic height
    setMobileMenuHeight()
    $(window).on 'resize', -> setMobileMenuHeight()

    # Desktop's menu's dynamic height
    setDesktopMenuHeight()
    $(window).on 'resize', -> setDesktopMenuHeight()

    markOpenable()
    formPopupRouter()
    automaticFormPopupDisplay()

    if $('body').is('.page-template-events')
      eventsPopup()

    if $('.popup').length
      popup()

    if $('.press-gallery').length
      pressCarousel()

  # Mark when we're scrolling down, for stying
  markScrolledDown = ->
    window.addEventListener 'scroll', (e) =>
      $('html').toggleClass('is-down', $(window).scrollTop() >= 50)
    , { passive: true }

  # Simple "router" for opening form popups
  formPopupRouter = ->
    prefix = '#/popup/'

    onHash = ->
      hash = window.location.hash
      $('[data-popup]').removeClass 'is-open'
      if hash.indexOf(prefix) != -1
        idParts = hash.replace(prefix, '').split('/')
        popupId = idParts[0]
        $popup = $('[data-popup="' + popupId + '"]')
        $popup.addClass 'is-open'

        # If there's another part of the hash, it's an event ID.
        # Find the event element that fits that ID, and put the form and event
        # intro from that event into the form
        if idParts[1]
          eventId = idParts[1]
          $eventElement = $('[data-event-id="' + eventId + '"]');
          eventIntro = $eventElement.data('event-intro')
          eventName = $eventElement.data('event-name')
          formIntro = $eventElement.data('event-form-intro')

          $('.trunk-popup-intro-field textarea').val(eventName)
          $('.trunk-show-id input').val(eventId)
          $('.page-id input').val(window.siteOptions.pageId)
          $('.form-popup__event-intro').html(eventIntro)
          $('.form-popup__form-intro').html(formIntro)

    onHash()
    $(window).on 'hashchange', -> onHash()

  # Automatically show popups after a timeout, if a data-popup-display and data-popup-timeout
  # Were found in the page
  automaticFormPopupDisplay = ->
    $('[data-popup-display]').each (i, item) ->
      slug = $(item).data('popup-display')
      timeout = $(item).data('popup-timeout') || 4000
      console.log item, slug, timeout
      setTimeout ->
        if window.location.hash.indexOf('#/popup') == -1
          window.location.hash = '#/popup/' + slug
      , timeout

  # Mark if we're currently hovering over an openable menu item.
  # Pure CSS solutions, in this case, aren't enough to keep the menu
  # from bobbing up and down between openable menu items, and giving
  # submenu-overlay a transition-delay looks weird when opening
  # and then immediately closing an item
  markOpenable = ->
    startClosing = null
    $body = $('body')

    $('.menu-item-has-children').hover(
      () ->
        $body.addClass('is-over-openable')
        clearInterval(startClosing)
      ,() ->
        startClosing = setInterval () ->
          $body.removeClass('is-over-openable')
        , 50
    )

  # Hide the collection page for a few seconds, by adding a class
  # to the body. Otherwise, we see the video loading, and it's not nice
  preloadCollection = ->
    setTimeout ->
      $('body.page-template-collection').addClass('is-collection-loaded')
    , 2000

  # Desktop menu's dynamic height
  setDesktopMenuHeight = ->
    $('.submenu').each (i, item) ->
      $item = $(item)
      innerHeight = $item.find('.submenu__wrapper').height()
      $item.parents('.main-menu-item').css('--inner-height', innerHeight + 'px')

  # Set mobile menu's max-height to end at the bottom of the screen,
  # so the menu will always be scrollable
  setMobileMenuHeight = ->
    headerHeight = $('#main-header').height()
    windowHeight = $(window).height()
    $('.menu-inner').css('max-height', windowHeight - headerHeight)

  # Popup
  popup = ->
    if !$.cookie('is-popup-shown')
      $('.popup').removeClass 'is-hidden'
      $('.popup-close').on('click', () -> $('.popup').addClass('is-hidden'))
      $.cookie('is-popup-shown', 'true', { path: '/', expires: 14 })

  # Resize the homepage so the "blocks" will always fit into the screen (older version)
  resizeHomepageBlocks = ->
    resize = ->
      $homepage = $ '.page-homepage'
      $mainContent = $ '.main-content'

      marginTop = parseInt $mainContent.css('margin-top').replace('px', '')
      ratio = $homepage.width() / $homepage.height()
      offset = parseInt $('.tile-background').css('bottom').replace('px', '')
      targetHeight = $(window).height() + offset - marginTop
      targetWidth = targetHeight * ratio

      $homepage.css 'max-width', targetWidth
      $mainContent.css
        'max-height': targetHeight - offset
        'min-height': 0
        'overflow': 'hidden'

    resize()
    $(window).on 'resize load', -> resize()

  # Sync the email fields in the newsletter signup form, because
  # at the moment, we need 2 different EMAIL fields (hebrew, english)
  # and the other one is always blank
  syncNewsletterFields = () ->
    selector = '.mc4wp-form input[type="email"]'
    $(selector).on 'keyup change', (e) ->
      $(selector).not(this).val($(this).val())

  # Events/trunkshows popup
  eventsPopup = () ->
    $popup = $('.trunk-popup')
    $intro = $popup.find('.trunk-popup-intro')
    $id = $popup.find('.trunk-show-id input')
    $pageId = $popup.find('.page-id input')
    $introField = $popup.find('.trunk-popup-intro-field textarea')

    # Close form
    closeForm = () => $popup.removeClass('is-open')
    $(document).on 'keyup', (e) -> if e.keyCode == 27 then closeForm()
    $(document).on 'click', '.close', (e) -> closeForm()

    stripTags = (html) ->
      tmp = document.createElement("DIV")
      tmp.innerHTML = html
      return tmp.textContent || tmp.innerText

    # Open form
    $(document).on 'click', '.event-button', (e) ->
      eventId = $(e.currentTarget).data('event-id')
      eventIntroHTML = $(e.currentTarget).data('event-intro')
      eventIntroText = stripTags(eventIntroHTML)
      $intro.html(eventIntroHTML)
      $id.val(eventId)
      $pageId.val(siteOptions.pageId)
      $introField.val(eventIntroText)
      $popup.addClass('is-open')


  # Press slideshow flickity
  pressCarousel = () ->
    $gallery = $('.press-gallery')

    flickity = $gallery.flickity({
      rtl: false,
      wrapAround: true,
      pageDots: false,
      cellAlign: if $gallery.is('.press-gallery-left') then 'left' else 'center',
      cellSelector: '.press-gallery-slide',
      imagesLoaded: true
    });

    # Auto-resize
    resize = () =>
      bottomMargin = parseInt($gallery.css('padding-bottom'))
      topMargin = parseInt($gallery.css('padding-top'))
      targetHeight = $(window).height() - topMargin - $gallery.position().top - bottomMargin
      $('.press-gallery-slide').height(targetHeight)

      # Resize the videos
      $('.slide-video').width($('.slide-video').height() * (16/9))
      $('.img').each () -> $(this).width(targetHeight * $(this).data('ratio'))

      $gallery.flickity('resize')

    resize()
    $(window).on 'resize load', () -> resize()

)(jQuery, Mobile, Util, Gallery, Popup)
